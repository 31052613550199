import { Controller } from "@hotwired/stimulus";

const DEVICE_TEXT = 'We would like to maintain receiver rankings and statistics of your device and link them to your account. To identify your device underneath all incoming connections, we need some extra information.';

export default class extends Controller {
    static targets = [
        "receiverType",
        "checkBoxRelatedContainer",
        "checkBoxExtraText",
        "checkBoxClaimDeviceText",
        "checkBoxClaimDeviceImgSrc",
        "checkBoxLabel",
        "serialInputLabel"
    ];

    static deviceInfo = {
        "dump1090": { extraText: null, claimDeviceText: DEVICE_TEXT, img: null },
        "dump1090-hptoa": { extraText: null, claimDeviceText: DEVICE_TEXT, img: null },
        "Radarcape": {
            extraText: 'Either use the built-in feeder functionality (if available) or install our feeder on the device. See <a target="_blank" href="/radarcape">here</a> for installation instructions.',
            claimDeviceText: null,
            img: null
        },
        "Kinetic SBS-3": {
            extraText: 'Please note that your SBS-3 must run a firmware version greater or equal to V119. For update instructions, see this <a target="_blank" href="https://www.youtube.com/watch?v=WHvS0bl-yQA">video</a>. You can use the BaseStation software for the SBS-3 receiver to set up client mode. The SBS-3 should connect to IP <strong>194.209.200.3</strong> and port <strong>10010</strong>.',
            claimDeviceText: `${DEVICE_TEXT} To identify your SBS-3, please provide the serial number that can be found on one of the stickers on the bottom side of the device. It is the number below the barcode without leading zeros. See the example image below.`,
            img: '/static/sbs3-serial.png'
        }
    };

    connect() {
        this.toggleInfo();
    }

    toggleInfo() {
        const receiverType = this.receiverTypeTarget.value;
        const deviceInfo = this.constructor.deviceInfo[receiverType] || {};

        // Manage Checkbox Visibility
        this.updateCheckboxVisibility(receiverType);

        // Update Checkbox Label
        this.updateCheckboxLabel(receiverType);

        // Update Serial Label
        this.updateSerialLabel(receiverType);

        // Update Extra Text and Claim Device Info
        this.updateExtraText(deviceInfo);
        this.updateClaimDeviceInfo(deviceInfo);
    }

    updateCheckboxVisibility(receiverType) {
        if (receiverType === "GRX1090") {
            this.checkBoxRelatedContainerTarget.classList.add("d-none"); // Hide checkbox
        } else {
            this.checkBoxRelatedContainerTarget.classList.remove("d-none"); // Show checkbox
        }
    }

    updateCheckboxLabel(receiverType) {
        if (!this.hasCheckBoxLabelTarget) return;

        const labelText = receiverType === "Kinetic SBS-3" ? "Client Mode" : "Push Feeder";
        this.checkBoxLabelTarget.querySelector("label").textContent = labelText;
    }

    updateSerialLabel(receiverType) {
        if (!this.hasSerialInputLabelTarget) return;

        const labelText = receiverType === "Radarcape" ? "Serial number:" : "Serial number:";
        this.serialInputLabelTarget.querySelector("label").textContent = labelText;
    }

    updateExtraText(deviceInfo) {
        this.checkBoxExtraTextTarget.innerHTML = deviceInfo.extraText || "";
    }

    updateClaimDeviceInfo(deviceInfo) {
        this.checkBoxClaimDeviceTextTarget.textContent = deviceInfo.claimDeviceText || "";

        if (deviceInfo.img) {
            this.checkBoxClaimDeviceImgSrcTarget.classList.remove("d-none");
            this.checkBoxClaimDeviceImgSrcTarget.src = deviceInfo.img;
        } else {
            this.checkBoxClaimDeviceImgSrcTarget.classList.add("d-none");
        }
    }
}

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    // connect is a lifecycle step
    connect() {
        // find all sub elements with bootstrap tool tip data-bs-toggle="tooltip"
        this.tooltips = this.element.querySelectorAll('[data-bs-toggle="tooltip"]');

        // bind all to bootstrap Tooltip
        this.tooltips.forEach(tooltip => new bootstrap.Tooltip(tooltip));
    }
}
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["input", "output"]

    connect() {
        this.updateAllCharCounts()
    }

    // targets are key values. Eg you can have multiple target with the same name for the same controller.
    updateAllCharCounts() {
        this.inputTargets.forEach((input, index) => {
            this.updateCharCount(input, this.outputTargets[index])
        })
    }

    updateCharCount(input, output) {
        if (!output) return
        // Get input value, removing newlines, to account for paste
        output.textContent = input.value.replace(/[\n\r]/g, "").length
    }

    confirmBeforeSubmit(event) {
        // Show a confirmation prompt
        const confirmed = confirm("Have you read 'How can I get access to OpenSky's data?' and 'Which data can OpenSky provide?' from the About/FAQ section?");

        // If the user cancels, prevent form submission
        if (!confirmed) {
            event.preventDefault();
        }
    }
}
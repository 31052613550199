// See https://docs.quarkiverse.io/quarkus-web-bundler/dev/advanced-guides.html#web-dependencies
// for more information about how to import web-dependencies:

// Example:
// in your pom.xml:
// <dependency>
// 	<groupId>org.mvnpm</groupId>
// 	<artifactId>jquery</artifactId>
// 	<version>3.7.1</version>
// 	<scope>provided</scope>
// </dependency>
//
// in this file:
// import $ from 'jquery'

// This app will be bundled by the Web-Bundler (including the imported libraries) and available using the {#bundle /} tag
// for more information about how to use the {#bundle /} tag, see https://docs.quarkiverse.io/quarkus-web-bundler/dev/advanced-guides.html#bundle-tag
// Sets the number of stars we wish to display

// CSS and Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import * as bootstrap from 'bootstrap';
import "./app.scss";

// Stimulus App
import { Application } from "@hotwired/stimulus";
import DataAccessController from './data_access_controller';
import HeaderController from './header_controller';
import FooterController from './footer_controller';
import RegisterSensorController from './register_sensor_controller';

const application = Application.start();
application.register('data-access', DataAccessController);
application.register('header', HeaderController);
application.register('footer', FooterController);
application.register('register-sensor', RegisterSensorController);

window.bootstrap = bootstrap;